import React, { Component } from 'react';
import { observer } from 'mobx-react';

import type { GlobalContextTyping, FlowRouteProps } from '../../types';
import { inHTOFlow } from '../../look-builder/utils/utils';
import auth from '../../services/Auth';
import EventStore from '../../stores/EventStore';
import CustomerStore from '../../stores/CustomerStore';
import CustomerFormStore from '../../stores/CustomerFormStore';
import { getMemberships } from '../../services/Events';
import EventFormStore from '../../stores/EventFormStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import { AccessContext } from '../../utils/HOC';
import Flow from '../../utils/HOC/Flow';
import { eventCreated, segmentIdentify } from '../../utils/metrics';
import { url } from '../../utils/window';
import { checkEventHTOStatus, formatPhone, HTO_STATUSES, validatePhoneArea } from '../../utils/utils';

type Props = FlowRouteProps<any> & {
  globalContext?: GlobalContextTyping;
};

type State = {
  phone?: string;
  error?: string;
  loading: boolean;
  redirectTo: string;
};

class Phone extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phone: '',
      error: undefined,
      loading: false,
      redirectTo: '',
    };
  }

  componentDidMount() {
    this.setState({
      phone: CustomerFormStore.phone,
    });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ phone: formatPhone(e.target.value) });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!this.state.phone || !validatePhoneArea(this.state.phone)) {
      return this.setState({
        error: 'Area codes cannot begin with "1".',
      });
    }

    try {
      this.setState({ loading: true });

      CustomerFormStore.update('phone', this.state.phone);

      await CustomerStore.update({
        ...CustomerStore.customer,
        ...{
          phone: CustomerFormStore.phone,
          firstName: CustomerFormStore.firstName,
          lastName: CustomerFormStore.lastName,
        },
      });

      auth.storeUserJson(CustomerStore.customer);

      await segmentIdentify(auth.user());

      if (this.isEditing()) {
        await this.setHTORedirectIfNecessary();

        return this.nextPage();
      }

      try {
        await Promise.allSettled([EventStore.create(EventFormStore.event), PartyRoleStore.loadIfEmpty()]);

        if (inHTOFlow(this.props.history.location.pathname)) {
          await segmentIdentify(auth.user());

          await this.setHTORedirectIfNecessary();
        } else {
          const trackingObject = {
            ...EventStore.event,
            eventType: EventFormStore.type,
            partyRoleId: EventFormStore.partyRoleId,
            usedDefaultEventDate: EventFormStore.usedDefaultEventDate,
            creatorEmail: CustomerStore.email ?? CustomerFormStore.email ?? auth.user().email,
          };

          if (EventFormStore.partyRoleId) {
            const partyRole = PartyRoleStore.find(EventFormStore.partyRoleId);

            if (partyRole) {
              trackingObject.role = partyRole.name;
            }
          }

          eventCreated(trackingObject);
        }

        this.nextPage();
      } catch (e) {
        let errorMessage = 'Failed to create event.';

        if (e instanceof Error) {
          errorMessage = e.message;
        }

        console.error(e);

        if (errorMessage.includes('already created an event')) {
          return this.props.history.push(`/account/events${this.props.history.location.search}`);
        }

        return this.setState({
          error: 'There was a problem creating your event',
        });
      }
    } catch (e) {
      console.error(e);

      this.setState({
        error: 'There was an error while saving your phone number.',
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  setHTORedirectIfNecessary = async () => {
    if (!EventStore.id) {
      await EventStore.loadEvent(auth.user().primaryEventId);
    }

    const memberships = (await (await getMemberships()).json()).data.customer.members;
    const htoStatus = checkEventHTOStatus({ event: EventStore.event, memberships });

    if (htoStatus === HTO_STATUSES.DATE_TOO_CLOSE) {
      return this.setState({
        redirectTo: '/hto/redirect',
      });
    }

    if (htoStatus === HTO_STATUSES.ALREADY_IN_PROGRESS) {
      return this.setState({
        redirectTo: '/hto/existing',
      });
    }
  };

  isEditing = () => url.param(this.props.location.search, 'edit') !== null;

  nextPage = () => {
    this.setState({
      error: undefined,
      loading: false,
    });

    if (this.state.redirectTo) {
      return this.props.history.push(this.state.redirectTo);
    }

    this.props.flow!(this.props.location.search);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          error={this.state.error}
          inputMode="tel"
          key="phone"
          label="Phone"
          loading={this.state.loading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          property="phone"
          required={true}
          requiredLength={14}
          title="What is your phone?"
          type="tel"
          value={this.state.phone!}
        />
      </>
    );
  }
}

export default Flow(AccessContext(observer(Phone)));
