import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';

import type { FlowRouteProps, GlobalContextTyping } from '../../types';
import LookBuilder from '../components/LookBuilder';
import Nav from '../components/Nav';
import auth from '../../services/Auth';
import { getMemberships } from '../../services/Events';
import ItemStore from '../../stores/ItemStore';
import PreviewStore from '../../stores/look-builder/PreviewStore';
import EventStore from '../../stores/EventStore';
import { isCategoryValidInCurrentFlow } from '../utils/utils';
import { lookSaved } from '../../utils/metrics';
import Flow from '../../utils/HOC/Flow';
import { AccessContext } from '../../utils/HOC';
import { itemIsBlocked, HTO_STATUSES, checkEventHTOStatus } from '../../utils/utils';
import windowUtils, { getParameterByName } from '../../utils/window';

type Props = FlowRouteProps<any> & {
  globalContext?: GlobalContextTyping;
};

const LookCreate = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting] = useState(false);
  const [error, setError] = useState('');

  const shouldRenderNav = !props.history.location.pathname.includes('/hto/');

  reaction(
    () => PreviewStore.lookPreview,
    () => {
      windowUtils.updateProductsAndBundleQueryString(PreviewStore.productsAndBundle);
    }
  );

  useEffect(() => {
    (async () => {
      if (!ItemStore.itemsFetched) {
        try {
          await ItemStore.fetchAndCache();
        } catch (e) {
          setError('Something Went Wrong');
        }
      }

      if (auth.signedIn() && window.gt.user.primaryEventId && props.history.location.pathname.includes('hto')) {
        const memberships = (await (await getMemberships()).json()).data.customer.members;

        if (!EventStore.event.id) {
          await EventStore.loadEvent(window.gt.user.primaryEventId);
        }

        const htoStatus = checkEventHTOStatus({ event: EventStore.event, memberships });

        if (htoStatus === HTO_STATUSES.DATE_TOO_CLOSE) {
          return props.history.push('/hto/redirect');
        }

        if (htoStatus === HTO_STATUSES.ALREADY_IN_PROGRESS) {
          return props.history.push('/hto/existing');
        }
      }

      // if comming from collection, store product url
      if (
        props.history.location.pathname.includes('/customize') &&
        !document.referrer.includes(window.location.pathname) // prevents setting prevUrl on refresh
      ) {
        PreviewStore.setPreviousUrl(document.referrer);
      }

      parameterSkusToBuilder();
      windowUtils.updateProductsAndBundleQueryString(PreviewStore.productsAndBundle);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parameterSkusToBuilder = () => {
    const bundleIds = getParameterByName('bundle_ids');
    const productSkus = getParameterByName('product_skus');

    if (productSkus) {
      const skus = decodeURIComponent(productSkus).split(',');

      const products = ItemStore.cachedProducts.filter(
        (p) => skus.includes(p.sku) && !itemIsBlocked(p) && isCategoryValidInCurrentFlow(p.category!)
      );

      if (products) {
        PreviewStore.setProducts(products);
      }
    }

    if (bundleIds) {
      const bundle = ItemStore.cachedItemsByCategory['jacket-and-pants'].find((i) => i.id === parseInt(bundleIds, 10));
      if (bundle && !itemIsBlocked(bundle)) {
        PreviewStore.setBundle(bundle);
      }
      // If there are no product skus in query string, but there is a bundle id
      // then populate Outfit store with recommnded items on bundle
      if (bundle && !productSkus) {
        PreviewStore.setBundleRecommendedItems(bundle);
      }
    }
  };

  const handleClose = () => {
    if (props.location.search.includes('htoSource=app')) {
      return props.history.push(`/event-flow/looks?eventId=${EventStore.id}`);
    }
    if (!props.history.location.pathname.includes('/customize')) {
      return (window.location.href = `${process.env.REACT_APP_ECOMM_URL}`);
    }
    if (!!PreviewStore.previousUrl) {
      return (window.location.href = PreviewStore.previousUrl);
    }
    return (window.location.href = `${process.env.REACT_APP_ECOMM_URL}/collection/tuxedos-and-suits`);
  };

  const setOutfitInLocalStorage = () =>
    window.localStorage.setItem(
      'outfit',
      JSON.stringify({
        outfit: PreviewStore.productsAndBundle,
      })
    );

  const handleSubmit = () => {
    const items = PreviewStore.productsAndBundle;
    const htoSource = new URLSearchParams(props.location.search).get('htoSource') ?? '';

    lookSaved(items, EventStore.event.id!, false);

    if (props.history.location.pathname.includes('/customize')) {
      setOutfitInLocalStorage();
    }

    let outflowIndex = 0;

    if (auth.signedIn()) {
      if (!window.gt.user.primaryEventId) {
        outflowIndex = 2;
      } else if (window.gt.user.firstName === '' || window.gt.user.lastName === '') {
        outflowIndex = 3;
      } else {
        outflowIndex = 1;
      }
    }

    if (props.history.location.pathname.includes('/hto-abr/')) {
      PreviewStore.setFlow('htoAbr-181113-125335');
    }

    if (props.history.location.pathname.includes('/hto/') || props.history.location.pathname.includes('/hto-abr/')) {
      props.flow!(
        EventStore.event.id
          ? `?redirect=false&htoFlow=true&eventId=${EventStore.event.id}&htoSource=${htoSource}`
          : undefined,
        outflowIndex
      );
    } else {
      props.flow!(EventStore.event.id ? `?eventId=${EventStore.event.id}` : undefined, outflowIndex);
    }
  };

  return (
    <>
      {shouldRenderNav && <Nav />}
      <LookBuilder
        error={error}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </>
  );
};

export default Flow(AccessContext(observer(LookCreate)));
