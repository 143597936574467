import { layers, shadows, hanger, thumbnailCrop } from '../data/layers';
import { Item, Bundle, Product, ProductMedia, BundleMedia, LookPreviewItem } from '../../types';
import {
  JACKET_AND_PANTS,
  SHIRT,
  TIE,
  VEST_AND_CUMMERBUND,
  POCKET_SQUARE,
  LAPEL_PIN,
  CUFFLINKS,
  BELT_AND_SUSPENDERS,
  SHOES,
  SOCKS,
} from '../../look-builder/data/categories';
import { capitalizeFirstLetter, isItemDisabled, itemHasRetailBundle } from '../../utils/utils';
import { bundleSortOrderMap } from '../../constants/bundles';

interface ProductObjects {
  url: string;
  classStr: string;
  catalogNumber: string;
  cleanProductType: string;
  productType: string | undefined;
  shirtCollar: string | null;
  layerName: string;
}

// -----------------------------------------------------------------------------
// IMAGE LAYER MAGIC
// -----------------------------------------------------------------------------
export const getProductImageUrl = (product: Item, layer: string) => {
  return makeProductImageObjects(product).filter((obj) => obj.layerName === layer)[0].url;
};

// Make all layers for a given product
// (e.x. jackets need two layers front, full)
export const makeProductImageObjects = (product: Item) => {
  const cleanProductType = getCleanedCategory(product);
  const catalogNumber = getCatalogNumber(product);
  const imgxPath = categoryToDirectory(cleanProductType);
  const imageCategoryName = imgxPath.includes('_tie') ? 'tie' : imgxPath;
  const layerObjects = layers[cleanProductType].map((layer) => {
    const layerName = layer.name ? `${layer.name}` : '';
    const imageName = `${catalogNumber}-${imageCategoryName}${layerName}`;
    let shirtCollar = null;

    if (cleanProductType === 'Shirt') {
      if (product.displayName!.includes('Wing')) {
        shirtCollar = 'wingtip';
      } else if (product.displayName!.includes('Point')) {
        shirtCollar = 'point';
      } else {
        shirtCollar = 'spread';
      }
    }

    return {
      url: `${window.gt.lookBuilderAssetsBaseUrl}/${imageName}.png`,
      classStr: `builder-image-${imageCategoryName}${layerName}`,
      catalogNumber,
      cleanProductType,
      productType: product.category,
      shirtCollar,
      layerName,
      thumbnailCrop: cleanProductType !== 'Pants' ? thumbnailCrop[cleanProductType][0].value : '',
    };
  });
  return layerObjects;
};

// Make all shadow layers for a given product
// (e.x. jackets a drop shadow image)
export const makeShadowImageObjects = (productObjects: ProductObjects[]) => {
  const cleanProductType = productObjects[0].cleanProductType;
  const productShadows = shadows[cleanProductType];
  let shadowName, classStr;

  if (!productShadows) {
    return [];
  }

  // handle the shirts
  if (cleanProductType === 'Shirt') {
    const collarType = productObjects[0].shirtCollar;
    shadowName = productShadows.filter((x) => x.includes(collarType ? collarType : ''));
    classStr = shadowName[0].split('.')[0];
  } else {
    shadowName = productShadows[0];
    classStr = shadowName.split('.')[0];
  }

  const path = `${window.gt.lookBuilderAssetsBaseUrl}/${shadowName}`;

  return [
    {
      url: path,
      classStr,
      catalogNumber: null,
      cleanProductType: null,
      productType: null,
    },
  ];
};

// Make hanger layer
// (e.x. socks and pants need hanger shown)
export const makeHangerObjects = (productObjects: ProductObjects[], previewProducts: Product[]) => {
  const cleanProductType = productObjects[0].cleanProductType;
  const previewIncludesSocksAndPants = (previewProducts: Product[]) =>
    previewProducts.reduce((acc: [], product) => {
      if (product.category === 'Socks') return [...acc, product];
      if (product.category === 'Pant') return [...acc, product];
      return acc;
    }, []).length >= 2;

  // if preview has both pants and socks, only make the hanger for pants (prevents 2 hangers)
  const needsHanger = previewIncludesSocksAndPants(previewProducts)
    ? !!hanger[cleanProductType] && cleanProductType === 'Pants'
    : !!hanger[cleanProductType];

  if (!needsHanger) {
    return [];
  }

  const hangerName = hanger[cleanProductType][0];
  const classStr = hangerName;
  const path = `${window.gt.lookBuilderAssetsBaseUrl}/${hangerName}`;

  return [
    {
      url: path,
      classStr: classStr,
      catalogNumber: null,
      cleanProductType: null,
      productType: null,
    },
  ];
};

// -----------------------------------------------------------------------------
// Why are products and bundles the way they are?
// -----------------------------------------------------------------------------

// allows common access to catalogNumber for product or bundle
export const getCatalogNumber = (product: Item) =>
  product.sku || product.catalogNumber || (product as Bundle).products!.filter((x) => x.sku.startsWith('1'))[0].sku;

// converts product category to slug style directory
export const categoryToDirectory = (category: string) => {
  return category.toLowerCase().replace(' ', '_').replace('/', '_').replace('(', '_').replace(')', '_');
};

// maps inconsistent product types to customer friendly names
export const getCleanedCategory = (product: Item) => {
  const jacketTypes = ['suit', 'tuxedo'];
  if (jacketTypes.indexOf(product.category!) > -1) {
    return 'Jacket';
  }

  if (product.category!.includes('Pant')) {
    return 'Pants';
  }

  if (product.category === 'Shoe') {
    return 'Shoes';
  }

  if (product.category === 'Tie' && !product.displayName!.includes('Bow')) {
    return 'Long Tie';
  }

  if (product.category === 'Tie' && product.displayName!.includes('Bow')) {
    return 'Bow Tie';
  }

  return product.category!;
};

// check if product matches customer friendly list categories
export const isProductInListCategory = (category: string, product: Item) => {
  if (product.category!.includes('Pant')) {
    return false;
  }

  if (product.category && category && category.includes(product.category.replace(/\s+/g, '-').toLowerCase())) {
    return true;
  }

  return false;
};

export const itemCategoryMatchesBuilderCategory = (activeItemCategory: string, itemCategory: string) => {
  if (itemCategory.toLowerCase() === 'preconfigured') {
    return activeItemCategory === JACKET_AND_PANTS;
  }
  if (itemCategory.toLowerCase() === 'shirt') {
    return activeItemCategory === SHIRT;
  }
  if (itemCategory.toLowerCase() === 'tie') {
    return activeItemCategory === TIE;
  }
  if (itemCategory.toLowerCase() === 'cummerbund' || itemCategory.toLowerCase() === 'vest') {
    return activeItemCategory === VEST_AND_CUMMERBUND;
  }
  if (itemCategory.toLowerCase() === 'pocket square') {
    return activeItemCategory === POCKET_SQUARE;
  }
  if (itemCategory.toLowerCase() === 'lapel pin') {
    return activeItemCategory === LAPEL_PIN;
  }
  if (itemCategory.toLowerCase() === 'cufflinks') {
    return activeItemCategory === CUFFLINKS;
  }
  if (itemCategory.toLowerCase() === 'belt' || itemCategory!.toLowerCase() === 'suspenders') {
    return activeItemCategory === BELT_AND_SUSPENDERS;
  }
  if (itemCategory.toLowerCase() === 'shoe') {
    return activeItemCategory === SHOES;
  }
  if (itemCategory.toLowerCase() === 'socks') {
    return activeItemCategory === SOCKS;
  }
  return false;
};

// -----------------------------------------------------------------------------
// Sorting
// -----------------------------------------------------------------------------
export const sortProductRealPretty = (a: Item, b: Item) => {
  // sort by type first. Vest v Cummerbund, belt v suspenders, etc.
  if (a.category! > b.category!) {
    return -1;
  }
  if (a.category! < b.category!) {
    return 1;
  }

  // sort by knot type. Self Tied v Pre Tied
  if (a.category === 'Tie') {
    if (a.attributeSecondary! < b.attributeSecondary!) {
      return -1;
    }
    if (a.attributeSecondary! > b.attributeSecondary!) {
      return 1;
    }
  }

  // sort by category. Long Tie v Bow Tie, Collar Type, etc.
  if (a.category === 'Shirt') {
    // we want shirts in reverse order so point collars come last
    if (a.attributePrimary! < b.attributePrimary!) {
      return -1;
    }
    if (a.attributePrimary! > b.attributePrimary!) {
      return 1;
    }
  }
  if (a.category === 'Tie' || a.category === 'Vest') {
    if (a.attributePrimary! > b.attributePrimary!) {
      return -1;
    }
    if (a.attributePrimary! < b.attributePrimary!) {
      return 1;
    }
  }

  // lapel pins use tertiary instead primary for the category
  if (a.category === LAPEL_PIN) {
    if (a.attributeTertiary! > b.attributeTertiary!) {
      return -1;
    }
    if (a.attributeTertiary! < b.attributeTertiary!) {
      return 1;
    }
  }

  // sort by color
  if (a.category === 'Shirt') {
    // reverse shirts so whites come first
    if (a.color! > b.color!) {
      return -1;
    }
    if (a.color! < b.color!) {
      return 1;
    }
  } else {
    if (a.color! < b.color!) {
      return -1;
    }
    if (a.color! > b.color!) {
      return 1;
    }
  }

  // sort by pattern. Stripes v Paisley, etc.
  if (a.category !== 'Shirt') {
    if (a.pattern! < b.pattern!) {
      return -1;
    }
    if (a.pattern! > b.pattern!) {
      return 1;
    }
  } else {
    if (a.pattern! < b.pattern!) {
      return -1;
    }
    if (a.pattern! > b.pattern!) {
      return 1;
    }
  }

  return 0;
};

// @ts-ignore
export const sortBy = (a: object, b: object, prop: string) => parseFloat(a[prop]) - parseFloat(b[prop]);

type ItemWithPosition = {
  position?: number;
} & Item;

/**
 * Marketing provided sorting
 */
export const customBundleSort = (items: Item[]): ItemWithPosition[] => {
  return items
    .map((item: ItemWithPosition) => {
      const newItem = { ...item, position: bundleSortOrderMap[String(item.displayName)] ?? 100 };

      return newItem;
    })
    .sort((a, b) => sortBy(a, b, 'position'));
};

// -----------------------------------------------------------------------------
// Filters
// -----------------------------------------------------------------------------
export const makeColorFilters = (
  activeCollection: string,
  isBundle = false,
  cachedProducts: Item[],
  cachedBundles: Item[]
): string[] => {
  if (isBundle) {
    return [
      ...new Set(
        cachedBundles
          .filter((bundle) => bundle.displayName!.includes('RECOMMENDED') === false)
          .map((bundle) => bundle.color!)
      ),
    ].sort((a, b) => (a > b ? 1 : -1));
  } else {
    return [
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.color!)
      ),
    ]
      .sort((a, b) => (a > b ? 1 : -1))
      .filter((a) => a !== 'Miscellaneous');
  }
};

export const makePatternFilters = (activeCollection: string, isBundle = false, cachedProducts: Item[]) => {
  if (isBundle) {
    return undefined;
  } else {
    return [
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.pattern!)
      ),
    ].sort((a, b) => (a > b ? 1 : -1));
  }
};

export const makeCategoryFilters = (activeCollection: string, isBundle = false, cachedProducts: Item[]) => {
  if (isBundle) {
    return ['suit', 'tux'];
  }

  if (activeCollection === SHIRT) {
    return ['pleated', 'twill'];
  }

  if (activeCollection === TIE) {
    return [
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.attributePrimary)
      ),
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.attributeSecondary)
      ),
    ].filter((a) => a !== null);
  }
  if (activeCollection === LAPEL_PIN) {
    return [
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.attributeTertiary)
      ),
    ].filter((a) => a !== null);
  }
  if (activeCollection === VEST_AND_CUMMERBUND) {
    return [
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.category)
      ),
      ...new Set(
        cachedProducts
          .filter((product) => isProductInListCategory(activeCollection, product))
          .map((product) => product.attributePrimary)
          .filter((attribute) => attribute !== 'Fancy')
      ),
    ].filter((a) => a !== null);
  }
};

// maps the display name to a customer friendly string
export const mapFilterName = (string: string) => {
  let filterName = string;

  // ties
  if (string === 'Windsor Tie') {
    filterName = 'Long Tie';
  }

  // vests
  if (string === 'not_tailored') {
    filterName = 'Satin';
  }

  return filterName ? capitalizeFirstLetter(filterName) : '';
};

/**
 * Maps color names to hexadecimal codes, defining order for swatch and collection page filters.
 */
export const colorToHexCode = {
  Black: '#000000',
  Gray: '#888888',
  Grey: '#888888',
  White: '#efefef',
  Yellow: '#f6eaab',
  Tan: '#d2b48c',
  Orange: '#f9905b',
  Red: '#c4383f',
  Pink: '#ffc0cb',
  Purple: '#8559c8',
  Blue: '#6779de',
  Green: '#416630',
  Brown: '#8a4038',
  Burgundy: '#800020',
} as const;

export type ColorFilter = keyof typeof colorToHexCode;

export type HexCode = (typeof colorToHexCode)[ColorFilter];

const normalizeColor = (color: string): string => {
  return capitalizeFirstLetter(color);
};

export const isColorFilter = (color: string): color is ColorFilter => {
  return normalizeColor(color) in colorToHexCode;
};
export const getHexCodeForFilter = (color: ColorFilter, isGrayBackground: boolean = false): HexCode => {
  const normalizedColor = normalizeColor(color) as ColorFilter;
  const hexCode = colorToHexCode[normalizedColor];

  if (hexCode === colorToHexCode.White && isGrayBackground) {
    return '#ffffff' as HexCode;
  }

  return hexCode;
};

/**
 * Retrieves a list of all color filters in the order they are defined.
 * @returns An array of `ColorFilter` values.
 */
export const getOrderedColorFilters = (): ColorFilter[] => {
  return Object.keys(colorToHexCode) as ColorFilter[];
};

/**
 * Orders product colors based on the predefined list of color filters and appends any additional colors not in the filter list.
 * @param productColors - An array of product colors as strings.
 * @returns A combined array of colors, starting with those present in both the productColors and the color filters, followed by additional product colors not in the color filters.
 */
export const getOrderedProductColors = (productColors: string[]): string[] => {
  const orderedColorFilters = getOrderedColorFilters();
  const productColorsSet = new Set(productColors);

  const orderedMutualColors = orderedColorFilters.filter((color) => productColorsSet.has(color)) as string[];

  const orderedMutualColorsSet = new Set(orderedMutualColors);
  // Find colors in productColors not included in getColorFilters() and append them
  const additionalColors = Array.from(productColorsSet).filter((color) => !orderedMutualColorsSet.has(color));

  // Return the combined array with additional colors appended
  return [...orderedMutualColors, ...additionalColors];
};

export const getBundleRecommendedItems = (bundle: Bundle) => {
  // make sure bundle is displayable
  const styledLooks = bundle.looks!.filter((l) => l.displayable)[0];

  // Check to see if there are valid recommended items from a bundle
  return styledLooks && styledLooks.products && styledLooks.products.length > 0
    ? styledLooks.products.filter((product) => product.sku[0] !== '1' && product.sku[0] !== '2' && product.displayable)
    : [];
};

export const prettifyCategory = (category: string) => {
  if (category.includes('-')) {
    return category
      .split('-')
      .map((s) => {
        if (s === 'and') {
          return '&';
        }
        return s.charAt(0).toUpperCase() + s.slice(1);
      })
      .join(' ');
  }

  return category.charAt(0).toUpperCase() + category.slice(1);
};

export const inHTOFlow = (pathname?: string) => {
  pathname ??= window.location.pathname;

  return pathname.includes('/hto/');
};

export const isHTOCategory = (category: string) =>
  Boolean([JACKET_AND_PANTS, SHIRT, TIE, VEST_AND_CUMMERBUND, SHOES].find((cat) => cat.includes(category)));

export const isCategoryValidInCurrentFlow = (category: string) => {
  if (inHTOFlow()) {
    return isHTOCategory(category.toLowerCase());
  }
  return true;
};

export const isItemValidInCurrentFlow = ({ isBundle, activeItem }: LookPreviewItem) => {
  if (!inHTOFlow() || !isBundle || !activeItem) {
    return true;
  }

  return (activeItem as Bundle).isRetail !== true;
};

export const lookLayers = (items: Item[]) =>
  items.reduce((acc, item) => {
    if (item.category! === 'preconfigured') {
      return [...acc, ...(item as Bundle).products!];
    }

    return [...acc, item as Product];
  }, []);

export const previewLayerNames = (items: Item[]) =>
  lookLayers(items)
    .map((product: Item) => product.category!.toLowerCase().replace(',', '-'))
    .join(' ');

export const makeJacketMediaArray = (product: Item): (ProductMedia | BundleMedia)[] => {
  return [
    {
      url: getProductImageUrl(product, '_full'),
      label: getCatalogNumber(product),
    },
  ];
};

export const isOptionDisabled = (item: Item, radioSelection: string, productsAndBundle: Item[]) => {
  // only check the item itself if no retailBundle or if we are still renting
  if (!itemHasRetailBundle(item) || radioSelection === 'Rent') {
    return isItemDisabled(item, productsAndBundle);
  }

  return isItemDisabled(item.retailBundle!, productsAndBundle);
};

export const areAllOptionsDisabled = (item: Item, productsAndBundle: Item[]) => {
  if (itemHasRetailBundle(item)) {
    return isItemDisabled(item.retailBundle!, productsAndBundle) && isItemDisabled(item, productsAndBundle);
  }

  return isItemDisabled(item, productsAndBundle);
};
